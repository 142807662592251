// ------------------------- lib imports -------------------------
import _ from 'underscore'
import React from 'react'
import {
    useRecoilState, useSetRecoilState,
} from 'recoil'
import AppState from '../data/AppState'
import UICore, { Mui } from './UICore';


// ------------------------- component imports -------------------------


// ------------------------- material-ui imports -------------------------
import {
    makeStyles, createStyles, Theme,
  } from '@material-ui/core';

// ------------------------- style definitions -------------------------
const useStyles = makeStyles((theme: Theme) => {


    return createStyles({
        
        root: { 
            position: 'fixed',
            top: '.33rem',
            right: '4rem',
            zIndex: theme.zIndex.tooltip
        },

    })
});


// ------------------------- interfaces -------------------------




// ------------------------- component def -------------------------
const ContactButton:React.FC = () => {
    const classes = useStyles();
    const isMobile = UICore.useIsMobile();
    
    const [slideIdx, setSlideIdx] =  useRecoilState<number>(AppState.atoms.slideIdx);
    const slidesMeta = useRecoilState<Record<string, any>>(AppState.atoms.slidesMeta) || [[]];

    
    return <Mui.Button onClick={()=>goToContact(setSlideIdx, slidesMeta, isMobile)} className={classes.root} 
        startIcon={<UICore.Icon icon={["fad", "envelope"]}/>}>
      Contact / Donate
    </Mui.Button>

} 
export default ContactButton

 

export const goToContact = (setSlideIdx:any, slidesMeta:any, isMobile:boolean) => {
    if(isMobile)
        document.getElementById("contactUs")?.scrollIntoView();

    setSlideIdx(_(slidesMeta[0]).keys().length - 1);
}