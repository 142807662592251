import React from 'react';
import config from '../data/config';

import _ from 'underscore';
import axios, { AxiosRequestConfig } from 'axios';


import AppState from '../data/AppState';

import dayjs from 'dayjs'


import UICore, { Mui } from './UICore';

// ------------------------- material-ui imports -------------------------
import {
    useMediaQuery, useTheme,
    fade,
    makeStyles, createStyles, Theme, TextField,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        root: {
            '& .MuiSnackbarContent-root': {
                flexDirection: 'column',
                padding: theme.spacing(1, 3, 1.5, 3),
                position: 'relative',
                zIndex: 1,

                '& .MuiTypography-h6': {
                    fontSize: '1.1rem'
                },


            }
        },

        minimized: {
            position: 'relative',
            width: '100vw',
            height: '2rem',
            top: 0,

            '& .MuiSnackbarContent-root': {
                width: "100%",
                height: "100%",
                borderRadius: 0,
                padding: 0,

                flexDirection: 'row',
                flexWrap: 'nowrap',

                justifyContent: "space-between",

                filter: 'drop-shadow(0px 4px 6px black)',
            },

            '& .MuiSnackbarContent-message': {
                padding: '0 0 0 2.5rem',
                filter: 'drop-shadow(2px 4px 6px black)',


                '& .MuiTypography-root': {
                    display: 'inline',
                    fontSize: '.9rem'
                }
            },

            '& .MuiSnackbarContent-action': {
                marginLeft: 0,
                marginRight: 0,

                '& .MuiLinearProgress-root': {
                    position: "absolute",
                    height: '2px',
                    bottom: '-2px',
                    left: 0,
                    right: 0,
                }
            }


        },


        icon: {
            fontSize: '5rem !important',
            top: 'auto',
            bottom: theme.spacing(2),
            left: theme.spacing(1),
            width: '1.25em',
            filter: 'blur(.5px) opacity(.1)'
        },

        iconMinimized: {
            fontSize: '1rem !important',
            bottom: 0,

            left: '8px',
            top: '3px',
            filter: 'opacity(.5)'
        }
        
    }),
);


const AppUpdater: React.FC = () => {

    const checkVersion = () => {        
        axios
            .get(config.versionCheckUrl + '?bust=' + dayjs().unix(), {
                withCredentials: true
            })
            .then((res:any) => {
                if(!res?.data?.version)
                    return;
        
                if(semverGreaterThan(res?.data?.version, config.currentVersion))
                    window.location.reload();

                    
            })
            .catch(err => {
                console.log(err);
            })
    }


    React.useEffect(() => {
        checkVersion();


        // 2020.8.4 JC - force SW to register and update during interval to force browser to check for files changes (deployed changes)
        // registration.update triggers update event in service-worker script, which then checks for changes
        const intervalId = setInterval(() => {
            checkVersion();
        }, 1000 * 60)  


        // Want to add calback (to show notification) when detect service worker update event
        //serviceWorker.register({ onUpdate: onSWUpdate });   

        // Make sure to clear interval during cleanup
        return () => clearInterval(intervalId);
    }, []);



    return null;
}


export default AppUpdater;



// from 
// https://dev.to/flexdinesh/cache-busting-a-react-app-22lk#:~:text=TL%3BDR%20%2D%20SemVer%20your%20app,when%20there's%20a%20version%20mismatch

// version from `meta.json` - first param
// version in bundle file - second param
const semverGreaterThan = (versionA:any, versionB:any) => {
    const versionsA = versionA.split(/\./g);
  
    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());
  
      const b = Number(versionsB.shift());
      // eslint-disable-next-line no-continue
      if (a === b) continue;
      // eslint-disable-next-line no-restricted-globals
      return a > b || isNaN(b);
    }
    return false;
  };
