// ------------------------- lib imports -------------------------
import React, { ReactDOM as ReactDOMtest } from 'react';
import _ from 'underscore';
import $ from 'jquery';
import dayjs from 'dayjs';

import AppState from '../../data/AppState'

import UICore, { Mui } from '../UICore';



import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';

import Odometer from 'odometer'
import 'odometer/themes/odometer-theme-default.css'



// ------------------------- component imports -------------------------
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import * as Slides from './Slides'
import { SlideStyleCtx } from './SlideDeck';

// ------------------------- material-ui imports -------------------------
import {
    makeStyles, createStyles, Theme,fade, useTheme, StyleRules, useMediaQuery
  } from '@material-ui/core';


  
export const Chart = (props:{
    type:string,
    options:any
}) => {
    const chartRef = React.useRef(null);

    
    const globalLoading = useRecoilValue<boolean>(AppState.atoms.globalLoading);

    const stdOpts = {
        chart: {            
            type: props.type,
            backgroundColor: 'transparent', 
            animation: false
        },
        title: {
            text: ''
        },        
        yAxis: {
            visible: false
        },
        xAxis: {
            visible: false
        },
        legend: {
            enabled: false
        },
    }

    const options =  _(stdOpts).extend(props.options);

    // 2021.11.30 ERL - apply defaults
    if(!options.plotOptions) options.plotOptions = {};
    if(!options.plotOptions.series) options.plotOptions.series = {};
    if(!options.plotOptions.series.animation)
        options.plotOptions.series.animation = {
            duration: globalLoading ? 0 : 1000
        }
    


    React.useEffect(()=>{         
        const resizer = () => {
            if(!chartRef?.current) return;

            const chartObj = chartRef.current as any;

            const $container = $(chartObj.container.current).closest('.innerGraphic');

            chartObj.chart?.setSize($container.width(), $container.height());
        };
        
        resizer();
        window.addEventListener("resize", resizer);

        return () => {
            window.removeEventListener("resize", resizer);
        }
    }, [])


    return <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef}/>
}




export const MainText = (props:{
    children?:any,
    left?:boolean,
    right?:boolean,
    center?:boolean,
    style?:React.CSSProperties,
    className?:string
}) => {
    const classes = React.useContext(SlideStyleCtx);
    
    return <Mui.Typography 
        className={UICore.clsx({
            [classes.mainStatement]: true,
            [props.className || '']: true,
            ['left']: !!props.left,
            ['right']: !!props.right,
            ['center']: !!props.center,
        })}
        variant="h1"
        style={props.style}>
        {props.children}
    </Mui.Typography>
}

export const Divider = (props:{
    center?:boolean,
    style?:React.CSSProperties
}) => {
    const classes = React.useContext(SlideStyleCtx);
    
    return <Mui.Divider style={props.style}
        className={UICore.clsx({
            [classes.divider]: true,
            ['center']: !!props.center,
        })}
    />
}


export const InfoBox = (props:{
    children?:any,
    graphic?:any,
    popup?:any, // component content 

    bare?:boolean,
    left?:boolean,
    right?:boolean,
    center?:boolean,
    width?: string
    className?:any,
    styles?: {
        root?: React.CSSProperties,
        inner?: React.CSSProperties,
        graphic?: React.CSSProperties,
        content?: React.CSSProperties,
        divider?: React.CSSProperties
    }
}) => {
    const classes = React.useContext(SlideStyleCtx);


    const renderBox = () => {
        return <Mui.Grid className="inner" style={props.styles?.inner}>
            
            <Mui.Grid className="innerContent" style={props.styles?.content}>
                {props.children}
            </Mui.Grid>
            {/* 2021.12.07 ERL - html2canvas ignores zindex and goes off ordering wtfffff */}
            <Mui.Grid className="innerGraphic" style={props.styles?.graphic}>
                {props.graphic}
            </Mui.Grid>
        </Mui.Grid>
    }
    
    return  <Mui.Grid 
        className={UICore.clsx({
            [classes.infograph]: true,
            [props.className]: true,
            ['left']: !!props.left,
            ['right']: !!props.right,
            ['center']: !!props.center,
            ['bare']: !!props.bare,
        })}
        style={props.styles?.root} >
    
        {!props.bare && <Divider style={props.styles?.divider} />}


        {props.popup 
            ? <Popup content={props.popup}>{renderBox()}</Popup>
            : renderBox() }
    </Mui.Grid>
}


export const ImageCloud = (props:{
    children?:any,
    folderName:string,
    renderImg?:(imgUrl:string)=>any,
    classes?: {
        container?:string,
        image?:string
    },
    randomize?:boolean,

    addlInserts?:any[] // 2021.12.03 ERL - anything extra we want to shove in (e.g. avatars when we're missing an image)
}) => {

    const theme = useTheme();

    const assetsMeta = useRecoilValue<any>(AppState.atoms.assetsMeta) || {};
    const images = assetsMeta[props.folderName];
    

    const imgComponents = _(images).map((imgFileName:string) => {
        const imgUrl = 'assets/' + props.folderName + '/' + imgFileName;

        return props.renderImg
            ? props.renderImg(imgUrl) 
            : <img src={imgUrl} className={props.classes?.image} />
    })

    const withInserts = _(imgComponents).union(props.addlInserts || []);
    const toDisplay = props.randomize ? _(withInserts).shuffle() : withInserts

    
    return <Mui.Grid container style={{
                width: '100%', 
                height: '100%',
                filter: theme.palette.type === 'light' ? 'drop-shadow(0 0 4px rgba(0,0,0,.4)' : undefined
            }}
            justifyContent="center" alignItems="center" 
            wrap="wrap" className={props.classes?.container}>

            {toDisplay}
        
    </Mui.Grid>
}






var rateSec = 12;
var counterTimeout:any = null;
const useCounterStyles = makeStyles((theme: Theme) => {
    return createStyles({
       root: {
           color: theme.palette.info[theme.palette.type],
           fontWeight: 'bold',
           fontSize: '3rem',
           position: 'fixed !important' as any,

           marginTop: '1.5rem',

           borderTop: `1px solid ${theme.palette.divider}`,
           borderBottom: `1px solid ${theme.palette.divider}`,

           textAlign: 'center',

           '& .odometer-ribbon-inner': {               
                transitionDuration: `${rateSec}s !important`,
                transitionTimingFunction: 'linear !important'  
           },

           '& .odometer-value:not(.odometer-first-value)': {
                color: theme.palette.warning[theme.palette.type],
           },
       }
    });
});
export const Counter = (props:{
    rateSec?:number,
    actual?:boolean,
    portal?:boolean

}) => {

    console.log(`duration: ${rateSec}`);

    const classes = useCounterStyles({ duration: rateSec });
    const ref = React.useRef(null);

    const getDropouts = () => {
        return Math.round((dayjs().unix() - dayjs().startOf("day").unix()) / (rateSec -1))
    }

    React.useEffect(()=>{
        if(!ref?.current) return;

            
    

        const initVal = getDropouts();

        const od = new Odometer({
            el: ref.current,
            selector: ".my-numbers",
            value: initVal,
            format: '(,ddd).dd',
            theme: 'default',
            duration: rateSec * 1000,
            animation: "count"
        });

        (ref.current as any).innerHTML = initVal;

        console.log(`${initVal} ==> ${od.value}`);

        
        od.render();

        const increment = () => {    
            const newVal = getDropouts() + 1;
            console.log(`started animating...????? ${od.value} => ${newVal}`);
            od.update(newVal);
            counterTimeout = setTimeout(increment, rateSec * 1000);
        }
        
        counterTimeout = setTimeout(() => {
            Odometer.init();
            increment();
        }, 100);

        return () => {
            console.log('clearing timeout...')
            clearTimeout(counterTimeout)
        }

    }, [ref?.current])


    return <div id="counter" className={`${classes.root} odometer my-numbers`}
        ref={ref}></div>
}


// 2021.12.09 ERL - havent found it, but something in odometer.js only works when the odo is on the page
// at load for initialization.  workaround now is to always have one, and just move it around...ugh.
export const CounterPortal = () => {
    const ref = React.useRef(null);


    const moveTo = (anchorSelector:any) => {
        
        const $anchor = $(anchorSelector).css("height", "3rem");

        const offsets = $anchor.offset() as any;

        (window as any).$('#counter')
            .css('top', offsets.top)
            .css('left', offsets.left)
            .width($anchor.parent().width())        
    }

    
    React.useEffect(()=>{
        if(!ref?.current) return;
        
        $('#counterParking').css('opacity', 1)
        moveTo(ref?.current); 

        return () => {
            moveTo("#counterParking")
            $('#counterParking').css('opacity', 0)
        }


    }, [ref?.current]);


    return <div ref={ref} />
}


const usePopupStyles = makeStyles((theme: Theme) => ({
    root: {
        cursor: 'pointer',
        
        '&:hover': {
            filter: `brightness(1.25) drop-shadow(0 0 4px ${theme.palette.common.black})`
        }
    },

    dialogPaper: {
        background: theme.palette.background.default,
        paddingTop: '2rem',
        textAlign: "left !important" as any,

    },

    dialogClose: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: theme.palette.divider
    },
}));
export const Popup = (props:{
    children?:any,
    tooltip?:any,
    content?:any,
    open?:boolean,
    onClose?:any,
    classes?:{ paper?:string }
}) => {
    const popupClasses = usePopupStyles();   
    const classes = React.useContext(SlideStyleCtx);
    const theme = useTheme();

    const [ open, setOpen ] = React.useState<boolean>(false);


    const renderTooltip = () => {
        return  <Mui.Tooltip arrow 
            className={classes.root}
            title={props.tooltip}
            onClick={()=>setOpen(true)}
        >
            {props.children}
        </Mui.Tooltip>
    };


    const close = ()=> {
        setOpen(false);
        props.onClose && props.onClose();
    }


    return <>
        
        {props.tooltip 
            ? renderTooltip()
            : <span className={popupClasses.root} onClick={()=>setOpen(true)}>{props.children}</span>}

        {props.content &&
        <Mui.Dialog open={open || !!props.open} 
            onClose={close} 
            classes={{ paper: popupClasses.dialogPaper + ' ' + classes.slide + ' ' + props.classes?.paper }}>
            <Mui.IconButton className={popupClasses.dialogClose}>
                <UICore.Icon icon={['fal', 'times']}/>
            </Mui.IconButton>
            <Mui.DialogContent>
                {props.content}
            </Mui.DialogContent>
            <Mui.DialogActions></Mui.DialogActions>
        </Mui.Dialog>}
    </>
}



export const Static = (props:{  children?:any }) => {
    const staticMode = useRecoilValue<boolean>(AppState.atoms.staticMode);

    return staticMode ? props.children : null;
}

export const Dynamic = (props:{ children?:any }) => {
    const staticMode = useRecoilValue<boolean>(AppState.atoms.staticMode);

    return !staticMode ? props.children : null;
}





// 2021.12.09 ERL - for specifying line breaks for a responsive size when we're micromanaging copy flow
export const Br = (props: { 
    xs?:boolean,
    sm?:boolean,
    // md?:boolean,
    // lg?:boolean,
    xl?:boolean
}) => {
    const theme = useTheme();    
    const isXs = useMediaQuery(theme?.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme?.breakpoints.down('sm'));    
    const isXl = useMediaQuery(theme?.breakpoints.down('xl'));


    if(props.xs && isXs
        || props.sm && isSm
        || props.xl && isXl)
        return <br/>

    return null;
} 


// 2021.12.09 ERL - for flipping the order of elements...extremely situational, but then we're in that situation often...
export const MobileReverse = (props:{
    children:React.ReactNode[],
}) => {
    const isMobile = UICore.useIsMobile();

    return <>
        { isMobile
            ? _(props.children as any).toArray().reverse()
            : props.children
        }
    </>
}







const useSourcesStyles = makeStyles((theme: Theme) => ({
    root: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '.5rem 15%',

        opacity: .5,
        fontStyle: 'italic',
        fontSize: '.8rem'
    },


}));
export const SourceList = (props:{ sources:string[] }) => {
    const classes = useSourcesStyles();

    const isMobile = UICore.useIsMobile();

    if(isMobile || !props.sources?.length) return null;

    return <Mui.Box className={classes.root}>
        {_(props.sources).map((src:string, idx:number) => 
        <span >
            <strong>{idx+1}</strong>) {src}
            {idx < props.sources.length-1 ? ', ' : ''}
        </span>)}
    </Mui.Box>; 
}


export const Footnote = (props:{ num:number }) => {    

    return <sup 
        style={{
            fontSize: '.5em',
            opacity: '.33',
            fontWeight: 'bold',
        }}>
        {props.num}
    </sup>; 
}