import PubSub from 'pubsub-js'
import _ from 'underscore';
import localforage from "localforage";


import {
    atom,
    selector,
    RecoilState, GetRecoilValue,
    RecoilValueReadOnly
} from 'recoil';


export default {     
    staticMode: atom<boolean>({
        key: "staticMode",
        default: false
    }),

    darkMode: atom<boolean>({
        key: "darkMode",
        default: true
    }),
    globalLoading: atom<boolean>({
        key: "globalLoading",
        default: false
    }),
    slideIdx: atom<number>({
        key: "slideIdx",
        default: 0
    }),
    
    slidesMeta: atom<Record<string, any>>({
        key: "slidesMeta",
        default: {}
    }),

        
    auxMeta: atom<Record<string, any>>({
        key: "auxMeta",
        default: {}
    }),

        
    openAux: atom<string>({
        key: "openAux",
        default: null
    }),

        
    assetsMeta: atom<Record<string, string[]>>({
        key: "assetsMeta",
        default: {}
    })
}