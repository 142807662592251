// ------------------------- lib imports -------------------------
import React, { ReactDOM as ReactDOMtest } from 'react';
import _ from 'underscore';
import $ from 'jquery';
import dayjs from 'dayjs';

import AppState from '../../data/AppState'
import UICore, { Mui } from '../UICore';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';



// ------------------------- component imports -------------------------
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import * as SD from "./SlideComponents"
import * as Aux from "./AuxContent"
import { SlideStyleCtx } from './SlideDeck';


import ContactForm from '../ContactForm'



// ------------------------- material-ui imports -------------------------
import {
    makeStyles, createStyles, Theme,fade, useTheme, StyleRules, lighten, useMediaQuery,
  } from '@material-ui/core';
 import {
    red
 } from '@material-ui/core/colors';






 const useTitleStyles = makeStyles((theme: Theme) => {

    return createStyles({
        accent: {
            color: theme.palette.text.secondary,
            fontSize: '.75em',
            fontWeight: 'bold',
            opacity:.125,
            margin: '0 .1em'
        },

        title: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '-5% !important',
            fontSize: '4.5rem !important'
        },

        subtitle: {
            position: 'absolute',
            bottom: '8rem',
            fontSize: '1.75rem !important',
            color: theme.palette.text.secondary,

            '& .MuiTypography-subtitle2': {
                fontSize: '1.2rem',
                marginTop: '.5rem'
            }
        }
    })
});

export const Title = () => {
    const classes = useTitleStyles();

    return <>
        
        <SD.MainText center className={classes.title}>
            <span className={classes.accent}>&lt;</span>
            TheSIS
            <span className={classes.accent}>&gt;</span>
        </SD.MainText>

        <SD.MainText center className={classes.subtitle}>
            “I think everybody in this country should learn how to program a computer—<br/>
            should learn a computer language, because it teaches you how to think.  And so I<br/>
             view computer science as a liberal art.”
                
            <Mui.Typography variant="subtitle2" color="textPrimary">
                - Steve Jobs, 1995 
            </Mui.Typography>
    
        </SD.MainText>

    </>;
}




export const EduCrisis = () => {
    const classes = React.useContext(SlideStyleCtx);
    const theme = useTheme();

    const isMobile = UICore.useIsMobile();



    const optionsDropouts = {
        plotOptions: {
            bar: {
                shadow: true,
                borderColor: theme.palette.background.default,
                pointPadding: 0,
                colorByPoint: true,
                colors: [ 
                    fade(theme.palette.warning[theme.palette.type], .2),
                    fade(theme.palette.warning[theme.palette.type], .4),
                    fade(theme.palette.warning[theme.palette.type], .6),
                    fade(theme.palette.warning[theme.palette.type], .8),                
                ]
            },
            series: {
                dataLabels: {
                    enabled: true,
                    shadow: false,
                    format: '{point.y:,.1f}%',
                    style: {
                        fontSize: ".9rem",
                        color: theme.palette.text.primary
                    }
                },
                tooltip: {
                    pointFormat:  '{point.y:,.1f}%'
                }
            }
        },
        yAxis: {
            visible: true,
            labels: { enabled: false },
            title: '',
            gridLineColor: "transparent",
            
            reversed: true,
        },
        xAxis: {
            opposite: true,
            lineWidth: 2,
            labels: { 
                x: 10,
                style: {
                    fontSize: '.85rem',
                    color: theme.palette.text.primary
                }
            },
            tickWidth: 0,

            categories: [
                'White',
                'Black',
                'Hispanic',
                'American Indian'
            ]
        },
        series: [ 

            {
                name: "Reason",
                data: [ 4.5, 7.0, 9.1, 11.0 ]
            }
         ]
    }

    const optionsReasons = {
        plotOptions: {
            bar: {
                shadow: true,
                borderColor: theme.palette.background.default,
                pointPadding: 0,
                
                colorByPoint: true,
                colors: [ 
                    fade(theme.palette.info.light, .8),          
                    fade(theme.palette.info.light, .6),
                    fade(theme.palette.info.light, .4),
                    fade(theme.palette.info.light, .2),      
                ]
            },
            series: {
                dataLabels: {
                    enabled: true,
                    shadow: false,
                    format: '{point.y:,.1f}%',
                    style: {
                        fontSize: ".9rem",
                        color: theme.palette.text.primary
                    }
                },
                tooltip: {
                    pointFormat:  '{point.y:,.1f}%'
                }
            }
        },
        yAxis: {
            visible: true,
            labels: { enabled: false },
            title: '',
            gridLineColor: "transparent",
        },
        xAxis: {
            
            lineWidth: 2,
            labels: { 
                x: -10,
                style: {
                    fontSize: '.85rem',
                    color: theme.palette.text.primary
                }
            },
            tickWidth: 0,

            categories: [
                'I was bored',
                'School wasn\'t relevant to my life',
                'I got into drugs',
                'Family issues or problems'
            ]
        },
        series: [ 

            {
                name: "Reason",
                data: [ 25.9, 20.3, 11.6, .8]
            }
         ]
    }



    return <>

        <SD.MainText left>
            We are <strong>failing</strong> the next generation. <SD.Br xl/> 
            <strong className="warning">7,000+</strong> students drop out of high school <strong>every day</strong><SD.Footnote num={1}/>, <SD.Br xl/>
            <strong className="info">1.2 million</strong> annually--disproportionately minorities<SD.Footnote num={2}/>.
        </SD.MainText>

{/* 
        <SD.InfoBox right>
            <SD.Static>
                <strong className="warning" style={{fontSize:'1.5em'}}>~7,000</strong><br/>
                Students will <br/>
                drop out <br/> today
            </SD.Static>
            <SD.Dynamic>
                <SD.CounterPortal/><br/>
                Students <br/> dropped out <br/> today
            </SD.Dynamic>
        </SD.InfoBox>   */}

     
        <SD.InfoBox right
            styles={{ 
                root: { },
                graphic: { width: '150%', height: '100%', filter: 'saturate(.9)'},
                // inner: { position: 'absolute', bottom: '-2rem' }
            }} 
            graphic={<SD.Chart type="bar" options={optionsDropouts}/>}>
        </SD.InfoBox>   

        <SD.MobileReverse>
            {/* <SD.InfoBox left 
                styles={{ 
                    root: { marginTop: '-3%', marginBottom: '-8%'},
                    graphic: { width: '120%', height: '100%', filter: 'saturate(.9)'},
                    // inner: { position: 'absolute', bottom: '-2rem' }
                }} 
                graphic={<SD.Chart type="column" options={options}/>}>
            </SD.InfoBox>   
            <SD.MainText right style={{ paddingLeft: '30%'}}>
                And falling college enrollment reveals the need for more value and accessibility.
            </SD.MainText> */}

            <SD.InfoBox left 
                styles={{ 
                    root: { marginTop: '-3%', marginBottom: '-8%'},
                    graphic: { width: '180%', height: '100%', filter: 'saturate(.9)'},
                    // inner: { position: 'absolute', bottom: '-2rem' }
                }} 
                graphic={<SD.Chart type="bar" options={optionsReasons}/>}>
            </SD.InfoBox>   
            <SD.MainText right style={{ paddingLeft: '30%'}}>
                They cite boredom and <strong className="info">irrelevance</strong> amongst the <br/>
                top reasons, more than drugs, gangs, or home instability<SD.Footnote num={3}/>.
            </SD.MainText>

        </SD.MobileReverse>


        <SD.SourceList sources={[
            `Miller, Tony. "Partnering for Education Reform." U.S. Department of Education, 2015`, 
            `National Center for Education Statistics, "Status and Trends in the Education of Racial and Ethnic Groups", nces.ed.gov 2016`,
            `America's Promise Alliance, "Building a Grad Nation", gradnation.americaspromise.org 2016`
        ]}/>

    </>
}



export const DevShortage = () => {
    const classes = React.useContext(SlideStyleCtx);
    const theme = useTheme();

    const isMobile = UICore.useIsMobile();


    const options = {
        plotOptions: {
            column: {
                shadow: true,
                borderColor: theme.palette.divider,
                pointPadding: 0
            },
            series: {
                dataLabels: {
                    enabled: true,
                    shadow: true,
                    format: '{point.name} <br/> {point.y:,.0f}',
                    style: {
                        fontSize: ".9rem",
                        color: theme.palette.text.primary
                    }
                }, 
                tooltip: {
                    pointFormat:  '{point.y:,.0f}'
                }
            }
        },
        series: [
            {
                data: [
                    { 
                        name: 'New Jobs', y: 190000,
                        color: theme.palette.info[theme.palette.type]
                    },
                    { 
                        name: 'CS Grads', y: 50000,
                        color: theme.palette.warning[theme.palette.type]
                    }
                ]
            }
        ]
    }


    

    return <>

        <SD.MainText left>
            As the world turns to <strong>technology</strong>, skills in <SD.Br sm /> <strong>software</strong> open new career opportunities <SD.Br sm/> for anyone.  
        </SD.MainText>


        <SD.InfoBox right>
            <strong className="info stat">9</strong> out of <strong className="info stat">10</strong> firms 
            see themselves more like <br/> <strong className="info">technology</strong> companies<SD.Footnote num={1}/>
        </SD.InfoBox>
        
        

        <SD.MobileReverse>
            <SD.InfoBox left 
                styles={{ 
                    root: { marginTop: '-3%', marginBottom: '-7%'},
                    graphic: { width: '120%', height: '120%'}
                }} 
                graphic={<SD.Chart type="column" options={options} />}>
            </SD.InfoBox>    
            
            <SD.MainText right>
                ...yet there is a massive, growing <strong>shortage</strong> of new developers<SD.Footnote num={2}/>
            </SD.MainText>
        </SD.MobileReverse>


        <SD.SourceList sources={[
            `“The Emergence of Big Code”, Dimensional Research, 2020`,
            `US Bureau of Labor Statistics, 2020`
        ]}/>
    </>

}


export const EduGap1 = () => {
    const classes = React.useContext(SlideStyleCtx);
    
    const stdClasses = UICore.useStyleCtxValue().stdClasses;
    const theme = useTheme();


    const options = {
        plotOptions: {
            pie: {
                shadow: false,    
                borderColor: theme.palette.background.default,
                borderWidth: '2px'            
            },

            series: {
                dataLabels: false,
                tooltip: {
                    pointFormat:  '{point.y:,.0f}%'
                }
            }
        },
        series: [
            {
                innerSize: '75%',
                data: [
                    { 
                        name: 'Has CS program',
                        y: 34,                        
                        color: theme.palette.warning[theme.palette.type],
                        
                    },
                    { 
                        name: 'Has AP courses',
                        y: 13,
                        color: theme.palette.info[theme.palette.type]
                    },
                    {          
                        name: 'No CS program',
                        y: 53,
                        color: theme.palette.background.paper
                    }
                ]
            }
        ]
    }

    const renderFigures = (filled:number, color:string) => {
        const totalFigures = 15;
        const iconStyles = {
            margin: '.25rem',
            fontSize: '1.33em',
        }

        return <Mui.Grid container style={{ width: '100%', margin: "1em"}} justify="center">
            {_(_.range(0, filled)).map(()=> 
            <UICore.Icon icon="female" style={_({ color: fade(color, .75) }).extend(iconStyles)} />)}

            {_(_.range(filled, totalFigures)).map(()=> 
            <UICore.Icon icon={["fal", "female"]} style={_({ opacity: .1 }).extend(iconStyles)} />)}
        </Mui.Grid>
    }

    return <>
        <SD.MainText center>
            The most technology-<strong>literate</strong> generation is being left behind by a system neither equitable nor effective.
        </SD.MainText>

        <SD.Divider center style={{marginBottom: '6rem' }}/>

        <Mui.Grid container justify="space-evenly">
            <SD.InfoBox bare 
                graphic={<SD.Chart type="pie" options={options} />} 
                styles={{
                    root: { width: '33%'}, 
                    inner: { width: '15rem' },
                    graphic: { width: '150', height: '150%', marginTop: '8%' },

                }}>
                Only <strong className="warning stat percent">47</strong> of high schools teach <strong>Computer Science</strong>;
                only <strong className="info stat percent">13</strong> at the AP level<SD.Footnote num={1}/>.

            </SD.InfoBox>

            
            <SD.InfoBox bare styles={{
                    root: { width: '33%'}, 
                    inner: { width:"22rem" } 
                }}>
                {renderFigures(10, theme.palette.info[theme.palette.type])}
                <strong className="info stat percent">66</strong> of girls under 12 are interested/enrolled in Computer Science.
                By college, <strong>only</strong> <strong className="warning stat percent">6</strong> remain<SD.Footnote num={2}/>.
                {renderFigures(1, theme.palette.warning[theme.palette.type])}
            </SD.InfoBox>
        </Mui.Grid>


        <SD.SourceList sources={[
            `“2020 State of Computer Science Education: Illuminating Disparities”, Code.org Advocacy Coalition, Computer Science Teachers Association, Expanding Computing Education Pathways Alliance`,
            `“Women in Coding – Hacking the Gender Gap”, Pueblo Science, 2018`
        ]}/>
    </>;
}



export const EduGap2 = () => {
    const classes = React.useContext(SlideStyleCtx);
    
    const stdClasses = UICore.useStyleCtxValue().stdClasses;
    const theme = useTheme();


    const options = {
        plotOptions: {
            bar: {
                shadow: true,
                borderColor: theme.palette.background.default,
                pointPadding: 0
            },
            series: {
                dataLabels: {
                    enabled: true,
                    x: '-100%',
                    shadow: false,
                    format: '{point.name}',
                    style: {
                        fontSize: ".9rem",
                        color: theme.palette.text.primary
                    }
                },
                tooltip: {
                    pointFormat:  '{point.y:,.1f}%'
                }
            }
        },
        yAxis: {
            visible: true,
            labels: { enabled: false },
            title: '',
            gridLineColor: "transparent",
        },
        xAxis: {
            lineWidth: 2,
            labels: { enabled: false },
            tickWidth: 0
        },
        series: [ {
            innerSize: '82%',
            data: [
                { 
                    name: 'Computer Science', y: 5.6,                        
                    color: theme.palette.warning[theme.palette.type],
                }, { 
                    name: 'Fine Arts', y: 3.7,
                    color: theme.palette.info[theme.palette.type]
                }, {          
                    name: 'Average', y: 2.9,
                    color: theme.palette.background.paper
                }, {          
                    name: 'Finance', y: 2.1,
                    color: fade(theme.palette.info[theme.palette.type], .33)
                }
            ]
        } ]
    }


    return <>
        <SD.MainText center>
            ...and even education <strong>wouldn't be enough</strong> as employers require more and more real-world experience.
        </SD.MainText>


        <Mui.Grid container justify="space-evenly" style={{position:'relative', overflow: 'hidden'}}>
            
                
            <SD.InfoBox left 
                styles={{
                    root: { margin: '5% 0 0 -18%' },
                    divider: { height: '2px' }
                }}>
                <strong className="info stat percent">61</strong> of "<strong>entry-level</strong>" 
                jobs require <strong className="warning stat">3+</strong> years experience<SD.Footnote num={1}/>.            
            </SD.InfoBox>
            
            <SD.InfoBox bare
                styles={{
                    root: { 
                        position: 'absolute',
                        margin: '5% 0 0 0',
                        width: '50%',
                        right: '-2%'
                    },
                    graphic: { 
                        width: "150%",
                        height: '16rem',
                        top: 0
                     },
                    content: {

                    }
                }} 
                graphic={<SD.Chart type="bar" options={options} />}>                    
            </SD.InfoBox>

            <SD.MainText left style={{ 
                fontSize: '1.75rem',
                color: fade(theme.palette.text.primary, .8),
                margin: '-7% 5% 0 0'
            }}>
                CS graduates rank <strong className="warning stat">#1</strong> in <br/>
                <strong>unemployment</strong> rate
            </SD.MainText>

        </Mui.Grid>


        <SD.SourceList sources={[
            `“The Science of the Job Search”, TalentWorks, 2018`,
            `National Center for Education Statistics, "Employment Outcomes of Bachelor's Degree Holders", nces.ed.gov 2020"`
        ]}/>
    </>;
}



const useAboutUsStyles = makeStyles((theme: Theme) => {

    return createStyles({
        image: {
            maxHeight: '15%', 
            maxWidth: '45%',
            margin: '2%'
        }
    })
});
export const AboutUs = () => { 
    const classes = useAboutUsStyles();

    return <>            

        <SD.InfoBox left 
            styles={{ 
                root: { margin: '0 0 -15% -75%'},
                divider: { display: 'none' },
                graphic: { height: '100%', width: '100%'}
            }} 
            graphic={<Mui.Avatar alt="Rebecca Flach" src="/assets/becca.png" style={{width:'100%', height: '100%'}}></Mui.Avatar>}>
        </SD.InfoBox>    

    
        <SD.MainText right style={{ paddingLeft: '25%'}}>
            Rebecca Flach, 17, started coding for fun, discovered 
            a whole <strong>new world</strong> of creativity, and wanted to share it with her
            friends and classmates.
        </SD.MainText>


        <SD.InfoBox left 
            styles={{ 
                root: { margin: '8% 0 -15% -75%'},
                divider: { display: 'none' },
                graphic: { height: '100%', width: '100%'}
            }} 
            graphic={<Mui.Avatar alt="Edwin Liu" src="/assets/edwin.png" style={{width:'100%', height: '100%'}}></Mui.Avatar>}>
        </SD.InfoBox>    

    
        <SD.MainText right style={{ paddingLeft: '25%'}}>            
            Industry veteran Edwin Liu likewise began as a teenager, <strong>fortunate</strong> to grow up 
            around computers, and spent much of his career developing young engineers.
        </SD.MainText>

    </>;
}



export const Solution1 = () => {
    return <>
        <SD.Divider center />
        <SD.MainText center>
            The <strong>only</strong> way to learn code is to actually <strong>build real things</strong>.
        </SD.MainText>
{/* 
        <SD.MainText center style={{marginTop: '5%'}}>
            <strong className="info">Anyone</strong> from any background can succeed with the right <strong className="info">experience</strong> making things.
        </SD.MainText> */}
        <SD.Divider center style={{marginTop: '5%'}}/>
    </>;
}




export const Solution2 = () => {
    return <>
        <SD.Divider center />
        <SD.MainText center>
            Anyone of <strong>any background</strong><br/> 
            <em>could</em> succeed--with the right experience <strong>making</strong> things.
            <br/><br/>
            We want to bring the opportunities <em>we</em> had to <strong>everyone</strong>.
        </SD.MainText>
        <SD.Divider center style={{marginTop: '5%'}}/>
    </>;
}

const useProgramStyles = makeStyles((theme: Theme) => {

    return createStyles({
        root: {
            flexWrap: 'nowrap', 
            justifyContent: 'center',
            width: '100%',

            marginTop: '5%',

            '& .MuiDivider-root': {
                width: 'auto',
                flexBasis: '10%',
                margin: '0 5%'
            }
        },
        infoBox: {
            width: '12rem'
        },

        bgIcon: {
            top: "15%",
            // filter: 'blur(1px) opacity(.5)'
        }
    })
});

export const OurProgram = () => { 
    const classes = useProgramStyles();
    
    const stdClasses = UICore.useStyleCtxValue().stdClasses;
    const theme = useTheme();


    return <>
        
        <SD.MainText center>
            We lead teams of student apprentices to build <strong>real software</strong>,<br/>
            practicing <strong>industry</strong> processes <br/>
            to solve <strong>real problems</strong> for their communities.
            <strong></strong>
        </SD.MainText>

        <Mui.Grid container alignItems="center" className={classes.root}>

            <SD.InfoBox bare className={classes.infoBox}>
                <UICore.Icon className={stdClasses.bgIcon + ' ' + classes.bgIcon} icon={["fad", "phone-laptop"]} size="6x"/>

                Apps and solutions <strong className="info">relevant</strong> to their lives
                
            </SD.InfoBox>
            <SD.Divider center />
            <SD.InfoBox bare className={classes.infoBox}>
                <UICore.Icon className={stdClasses.bgIcon + ' ' + classes.bgIcon} icon={["fad", "code"]} size="6x"/>
                
                Modern development using <strong className="info">in-demand</strong> skills and processes
            </SD.InfoBox>
            <SD.Divider center />
            <SD.InfoBox bare className={classes.infoBox}>
                <UICore.Icon className={stdClasses.bgIcon + ' ' + classes.bgIcon} icon={["fad", "users"]} size="6x"/>

                Real <strong className="info">impact</strong> on real people
            </SD.InfoBox>
        </Mui.Grid>
    </>;
}





const useTrackRecordStyles = makeStyles((theme: Theme) => {

    return createStyles({
        image: {
            maxHeight: '15%', 
            maxWidth: '45%',
            margin: '2%'
        }
    })
});
export const TrackRecord = () => { 
    const classes = useTrackRecordStyles();

    return <>
        
        <SD.MainText left>
            We've built our method over two decades of building teams of professionals, interns, and apprentices.
        </SD.MainText>

        <SD.InfoBox right 
            styles={{
                graphic: {
                    top: '-1.5rem', right: '-2rem'
                }
            }}
            //graphic={<Mui.Avatar src="assets/edwin.png" style={{ height: '7rem', width: '7rem', filter: 'opacity(.9) saturate(0.75)' }}/>}
            >
            <strong className="info">20+</strong> years <br/> training devs, <br/>
            <strong className="info">30+</strong> interns and apprentices since 2018
        </SD.InfoBox>
        
        <SD.InfoBox left 
            graphic={<SD.ImageCloud folderName="companies" classes={{ image: classes.image }} randomize/>}
            styles={{
                root: { marginTop: '-3%', marginBottom: '-7%'},
                graphic: {
                    width: '166%',
                    height: '125%'
                }
            }}>            
        </SD.InfoBox>
          
        <SD.MainText right style={{ paddingLeft: '32%'}}>
            Our alums have all gone on to successful software careers as coders, designers, and managers.
        </SD.MainText>
    </>;
}





const usePilotStyles = makeStyles((theme: Theme) => {
    return createStyles({
        appBox: {
            '& img': {
                margin: '.25rem',
                height: '80%'
            }
        }, 
        studentBox: {
            '& .MuiAvatar-root': {
                height: "7rem",
                width: '7rem',
                margin: '0 1rem',
                border: `1px solid ${theme.palette.divider}`
            },
        }
    })
});
export const PilotProgram = () => {
    const classes = usePilotStyles();
    
    const stdClasses = UICore.useStyleCtxValue().stdClasses;
    const theme = useTheme();

    return <>
        <SD.MainText center>
            Our <strong>pilot program</strong> began with Rebecca, who brought together 
            classmates to improve an app used every day by students, parents, and teachers.
        </SD.MainText>


        <Mui.Grid container justify="space-evenly" style={{ position:'relative', margin: '3% 0 4% 0'}}>                
            <SD.InfoBox left className={classes.studentBox}
                styles={{
                    root: { margin: '0 0 0 -20%', overflow: 'visible' },
                    divider: { height: '2px' },
                    graphic: { 
                        top: '-1rem',
                        height: '100%',
                        width: '150%',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }
                }}
                graphic={<>
                    <Mui.Avatar alt="Rebecca Flach" src="/assets/becca.png" style={{ height: '8rem', width: '8rem', margin: '0 6rem' }}>R</Mui.Avatar>
                    <Mui.Avatar alt="Karen" src="/assets/karen.png">K</Mui.Avatar>
                    <Mui.Avatar alt="Jacob" src="/assets/jacob.png">J</Mui.Avatar>
                </>}
                >
            </SD.InfoBox>
            
            <SD.InfoBox bare className={classes.appBox}
                styles={{
                    root: { 
                        position: 'absolute',
                        margin: '0 0 0 33%',
                        width: '50%',
                    },
                    graphic: { 
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                     }
                }}
                graphic={<>
                    <img src="/assets/thesisapp1.png" alt="image" />
                    <img src="/assets/thesisapp2.png" alt="image" />
                    <img src="/assets/thesisapp3.png" alt="image" />
                    <img src="/assets/thesisapp4.png" alt="image" />
                </>}>   

                 
            </SD.InfoBox>
        </Mui.Grid>

        
        <SD.MainText center>
            With our <strong>structure</strong> and <strong>approach</strong>, they can make this a reality.
        </SD.MainText>
    </>;
}








const useSupportersStyles = makeStyles((theme: Theme) => {
    const rotation = 10;
    return createStyles({
       supporterBox: {
            overflow: 'visible' ,
            transform: `rotate(${rotation * -1}deg)`,

            '& .innerGraphic': {
                top: '-1rem',
                height: '100%',
                width: '120%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                
                transform: `rotate(${rotation}deg)`,
            },

            '& .innerContent': {
                transform: `rotate(${rotation}deg)`,
                position: 'absolute',
                bottom: '-4rem'
            }


       },
       supporterImg: {
           height: '7rem',
           width: '7rem',
           margin: '-1.75rem .5rem' ,          
           fontSize: '3rem'   
       },

       supporterImg_uhs: {
           height: '5rem',
           width: '5rem',
           margin: '.33rem',
       },
       supporters_uhs: {
           justifyContent: 'flex-start'
       }
    })
});
export const Supporters = () => {
    const classes = useSupportersStyles();

    
    return <>
        <SD.MainText style={{textAlign: 'left', width: "85%", margin: "0 0 0 -6%"}}>
            This pilot at University High School in Tucson has found overwhelming support from educators and 
            administrators <strong>pushing for expansion</strong> to other schools across the state.
        </SD.MainText>


        <Mui.Grid container justify="space-evenly" style={{ position:'relative', margin: '3% 0 4% 0'}}>                
            <SD.InfoBox left className={classes.supporterBox}
                styles={{
                    root: { margin: '10% 0 0 -10%', },
                    divider: { height: '2px', width: '28%' },
                }}
                graphic={<SD.ImageCloud 
                    folderName="supporters_uhs" 
                    randomize
                    addlInserts={[
                        <Mui.Avatar alt="Kim Grimes - Teacher-Librarian" className={classes.supporterImg_uhs}>K</Mui.Avatar>
                    ]}
                    classes={{
                        container: classes.supporters_uhs,
                        image: classes.supporterImg_uhs
                    }} />}
                >
                    University HS
            </SD.InfoBox>

            <SD.InfoBox left className={classes.supporterBox}
                styles={{
                    root: { margin: '0 0 0 -31%'},                    
                    divider: { height: '2px', width: '28%' },
                }}
                graphic={<SD.ImageCloud 
                    folderName="supporters_tusd" 
                    randomize
                    addlInserts={[
                        <Mui.Avatar alt="Lisa Wood-Hromidko - Internships &amp; Business Development" className={classes.supporterImg}>L</Mui.Avatar>
                    ]}
                    classes={{
                        image: classes.supporterImg
                    }} />}
                >
                    Tuscon Unified School District
            </SD.InfoBox>


            <SD.InfoBox right className={classes.supporterBox}
                styles={{
                    root: {
                        width: '33%',
                        margin: '-6% 0 0 -29%' 
                    },
                    divider: { display: 'none' },
                }}
                graphic={<SD.ImageCloud 
                    folderName="supporters_azdoe" 
                    randomize
                    addlInserts={[
                        <Mui.Avatar alt="Julie Ellis - CTE Student Orgs" className={classes.supporterImg}>J</Mui.Avatar>
                    ]}
                    classes={{
                        image: classes.supporterImg
                    }} />}
                >
                    Arizona Dept of Education
            </SD.InfoBox>
            
        </Mui.Grid>
    </>;
}





export const Mission = () => {


    return <>
        
        <SD.Divider center />
        <SD.MainText center>
            We are seeking <strong>$250,000</strong> to build a <strong>1-year</strong> Beta program.<br/>
            With <strong className="info">20 students</strong> from UHS, we will
            establish our <strong>methodology</strong>, with <strong>metrics</strong> to prove
            our <strong>effectiveness</strong>
        </SD.MainText>
        
        <SD.MainText center style={{marginTop: '5%'}}>
            This program can be a model for <strong>every school</strong> to 
            have <strong>student-driven</strong> software teams led by <strong>industry experts</strong>
        </SD.MainText>
        
        <SD.Divider center />
    </>;
}



const useDonateStyles = makeStyles((theme: Theme) => {
    
    return createStyles({
       donateContainer: {
            // display: 'flex',
            // minHeight: '20rem',
            // maxHeight: '50vh',
            // maxWidth: '22rem'
       },

       ctaWrapper: {
           display: 'flex',
           justifyContent: 'center',
           alignItems: 'center',
           flexDirection: 'column',

       }
    })
});
export const Donate = () => {
    const classes = useDonateStyles();

    const donorbox_embed =`
    <a class="dbox-donation-button"
        style="background: #1976d2 url(https://donorbox.org/images/red_logo.png) no-repeat 37px;color: #fff;text-decoration: none;font-family: Verdana,sans-serif;display: inline-block;font-size: 16px;padding: 15px 38px;padding-left: 75px;-webkit-border-radius: 2px;-moz-border-radius: 2px;border-radius: 2px;box-shadow: 0 1px 0 0 #1f5a89;text-shadow: 0 1px rgba(0, 0, 0, 0.3);" 
        href="https://donorbox.org/thesis-program-beta?default_interval=o&hide_donation_meter=true">
    Donate</a>`
    // `<script src="https://donorbox.org/widget.js" paypalExpress="false"></script><iframe src="https://donorbox.org/embed/thesis-program-beta?default_interval=o&hide_donation_meter=true" name="donorbox" allowpaymentrequest="allowpaymentrequest" seamless="seamless" frameborder="0" scrolling="no" height="900px" width="100%" style="max-width: 500px; min-width: 250px; max-height:none!important"></iframe>`;

    return <>
        <SD.MainText center>
            We'd love to hear from you.  
        </SD.MainText>

        <SD.Divider center />


        
        <ContactForm />
        


        
        <SD.Divider center />
        <SD.MainText center style={{marginTop: '-2rem'}}>
            <div className={classes.donateContainer} dangerouslySetInnerHTML={{__html: donorbox_embed}}></div>
        </SD.MainText>

        
{/* 
        <Mui.Grid>
            <div dangerouslySetInnerHTML={{ __html: donorbox_embed }}></div>
        </Mui.Grid> */}
    </>;
}

