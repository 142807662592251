// ------------------------- lib imports -------------------------
import React from 'react';
import _ from 'underscore';
import dayjs from 'dayjs';
import config from './data/config';

import AppState from './data/AppState'

import { MemoryRouter as Router, Switch, Route } from 'react-router-dom';
import icon from '../../assets/icon.svg';
import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { far as far_pro } from '@fortawesome/pro-regular-svg-icons'
import { fas as fas_pro } from '@fortawesome/pro-solid-svg-icons'
import { fal as fal_pro } from '@fortawesome/pro-light-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'



import {
  RecoilRoot,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import db from './data/db';

import UICore, { Mui } from './components/UICore';

// ------------------------- component imports -------------------------

import ThemeToggle from './components/ThemeToggle';
import { SlideDeck } from './components/slides/SlideDeck';
import * as SD from './components/slides/SlideComponents';
import GlobalControls from './components/GlobalControls';
import MainMenu from './components/MainMenu';

import AppUpdater from './components/AppUpdater'

import ContactButton from './components/ContactButton';

import Odometer from 'odometer'
import 'odometer/themes/odometer-theme-default.css'

import { init } from 'emailjs-com'


// ------------------------- material-ui imports -------------------------
import {
  AppBar,
  CssBaseline,
  MuiThemeProvider,  
  createTheme, useMediaQuery, fade, colors,
  makeStyles, createStyles, Theme
} from '@material-ui/core';

import { blue, orange, yellow, amber, green, lightGreen } from '@material-ui/core/colors'



// ------------------------- style definitions -------------------------
library.add(fas, far, far_pro, fas_pro, fal_pro, fad);
// ------------------------- interfaces -------------------------
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

init(config.APIs.emailjs.UserId)


// ------------------------- component def -------------------------
export default function App() {

  
  (globalThis as any)._ = _;
  (globalThis as any).dayjs = dayjs;

  const AppRoot = () => {
    const stdClasses = UICore.useStyleCtxValue();
    const prefersDark = true; //  useMediaQuery('(prefers-color-scheme: dark)'); // 2021.10.01 ERL - y no work in electron
    
    const [isDarkMode, setIsDarkMode] = useRecoilState<boolean>(AppState.atoms.darkMode)


    React.useEffect(()=>{      
      setIsDarkMode(prefersDark);
    }, [])
  
    

    const componentOverrides = {
        MuiBackdrop: {
            root: {
                ['-webkit-app-region']: 'no-drag'
            }
        }
    };

    const theme = React.useMemo(
        () => {
            if (isDarkMode) {
                return createTheme({
                    palette: {
                        type: 'dark',
                        info: {
                            main: blue[500],
                            dark: blue[700],
                        },                        
                        warning: {
                          main: yellow[500],
                          dark: yellow[700],
                          
                      },
                    }, 
                    overrides: componentOverrides
                })
            } else {
                return createTheme({
                    palette: {
                        type: 'light',
                        background: {
                            default: colors.grey[100]
                        },
                        info: {
                            light: blue[300],
                            main: blue[500],
                        },                        
                        warning: {
                          light: yellow[800],
                          main: yellow[800],
                          
                        }
                    },
                    overrides: _(componentOverrides).extend({
                        MuiBackdrop: {
                            root: {
                                backgroundColor: fade('#fff', .75)
                            }
                        }
                    })
                })
            }
        },
        [isDarkMode, componentOverrides],
    );
    
    return <MuiThemeProvider theme={theme}>
      <CssBaseline>

        <UICore.StyleContext.Provider value={stdClasses}>
        <Router>
          <Switch>
            <Route path="/" component={Hello} />
          </Switch>
        </Router>

        <AppUpdater/>
        </UICore.StyleContext.Provider>
      </CssBaseline>
    </MuiThemeProvider>
  }


  return (
    <RecoilRoot>
      <AppRoot/>
    </RecoilRoot>
  );
}



const useStyles = makeStyles((theme: Theme) => {

  return createStyles({
      root: {
          
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background: 'none',
      },
      toolbar: {
        padding: theme.spacing(0, 1, 0, 0),
        justifyContent: 'space-between',
        opacity: '.5',
      },

      deck: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        //background: theme.palette.type === "dark" ? theme.palette.common.black : theme.palette.common.white, //theme.palette.background.default,
        // borderBottom: `1px solid ${theme.palette.background.default}`,
        flexGrow: 1,

        width: '100vw',

        overflowX: 'hidden'
      },

      
      counterContainer: {
        position: 'fixed',
        bottom: 0, 
        right: 0,
        zIndex: 10,
    }
  
  })
});


const Hello = () => {

  const classes = useStyles(); 
  
  const setAssetsMeta = useSetRecoilState<Record<string, string[]>>(AppState.atoms.assetsMeta)
  const [isLoading, setLoading] = useRecoilState<boolean>(AppState.atoms.globalLoading);

  React.useEffect(()=>{
    setLoading(true);
      
    fetch('assets/assetsMeta.json', 
    {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'            
      }
    })
    .then((res:any) => {
      return res.json();
    })
    .then((res:any) => {
      setAssetsMeta(res);
      setLoading(false);
    });


    
    (window as any).odometerOptions = {
      auto: false, // Don't automatically initialize everything with class 'odometer'
    };

  }, [])

  return <>
    <GlobalSpinner/>

    <AppBar className={classes.appBar} elevation={0}>     
      <Mui.Toolbar variant="dense" className={classes.toolbar}>
        <MainMenu/>

        <GlobalControls />

        <ThemeToggle/>           
      </Mui.Toolbar>      
    </AppBar>

    <Mui.Grid className={classes.deck}>
      <SlideDeck/>
    </Mui.Grid>

    
    <ContactButton />

    <div id="counterParking" className={classes.counterContainer}>
      <SD.Counter/>
    </div>
      
  </>;
};



const GlobalSpinner = () => {
  const isLoading = useRecoilValue<boolean>(AppState.atoms.globalLoading);
  if(!isLoading) return null;

  return <UICore.Spinner/>
}