// ------------------------- lib imports -------------------------
import React, { ReactDOM as ReactDOMtest } from 'react';
import _ from 'underscore';
import $ from 'jquery';
import dayjs from 'dayjs';

import AppState from '../../data/AppState'

import UICore, { Mui } from '../UICore';



import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';

import Odometer from 'odometer'
import 'odometer/themes/odometer-theme-default.css'



// ------------------------- component imports -------------------------
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import * as Slides from './Slides'
import * as Aux from "./AuxContent"
import * as SD from './SlideComponents'

// ------------------------- material-ui imports -------------------------
import {
    makeStyles, createStyles, Theme,fade, useTheme, StyleRules, useMediaQuery
  } from '@material-ui/core';


  

// ------------------------- style definitions -------------------------

const useStyles = makeStyles((theme: Theme) => {

    const infoboxWidth = '15vmax';
    const infoboxWidth_sm = "33vmin"



    return createStyles({
        root: {
            width: "calc(100vw - 2rem)",
            height: '56.25vw', // 2021.11.30 ERL - force 16:9 for ease of print/pdf conv
            background: theme.palette.background.default,
            paddingBottom: '2rem',  // 2022.01.17 ERL - pad for sources/footnotes

            [theme.breakpoints.down('sm')]: {
                width: "calc(100vw - 3rem)",
                height: '100vh',
                overflow: 'visible'
            }
        },

        slide: {
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",

            position: 'relative', 


            '& strong.info': {
                color: theme.palette.info[theme.palette.type]
            },
            '& strong.warning': {
                color: theme.palette.warning[theme.palette.type]
            },
            
            '& strong.stat': {
                fontSize: '1.2em !important',
                marginRight: '-.05em'
            },

            '& .highcharts-credits': { /* 2021.11.29 ERL - i'm sorry */
                display: 'none'
            },

            '& .stat.percent:after' : {
                fontSize: '.8em',
                content: '"%"'   
            },
        },

        divider: {
            backgroundColor: fade(theme.palette.info[theme.palette.type], .5),

            '&.center': {
                width: '33%',
                height: '2px',
                margin: '2.5vmax 0'
            },
        },

        mainStatement: {
            fontSize: "3rem",
            width: "100%",
            
            marginTop: '2vmax',

            '&.right' : {
                paddingLeft: '30%',
                textAlign: 'left'
            },

            '&.left' : {
                paddingRight: '30%',
                textAlign: 'right'
            },

            '&.center': {
                width: '80%'
            },

            [theme.breakpoints.down('sm')]: {

                '&.right' : {
                    paddingLeft: '0% !important',
                    textAlign: 'right'
                },
    
                '&.left' : {
                    paddingRight: '0%',
                    textAlign: 'left'
                },
    
                '&.center': {
                    width: '100%'
                },
            }

        },

        txtPrimary: {
            color: theme.palette.info[theme.palette.type]
        },
        
        txtWarning: {
            color: theme.palette.warning[theme.palette.type]
        },

        infograph: {
            width: '50%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',

            marginTop: '-5.5vmax',
            
            '&:not(:last-child)': {
                marginBottom: '2vmax',
            },    
            

            '&.right': {
                marginRight: '-25%',
            },

            '&.left': {
                flexDirection: 'row-reverse',
                marginLeft: '-25%',
            },

            '& .MuiDivider-root': {
                width: `calc(100% - ${infoboxWidth})`,
            },

            '& .inner': {
                height: infoboxWidth,
                width: infoboxWidth,
                border: `2px solid ${fade(theme.palette.info[theme.palette.type], .5)}`,
                borderRadius: '100%',
                background: fade(theme.palette.background.paper, .5),

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                position: 'relative',
                zIndex: 0,

                '& .innerContent': {
                    color: fade(theme.palette.text.primary, .8),
                    fontSize: '1.75rem',
                    filter: `drop-shadow(0 0 1rem ${theme.palette.type === 'dark' ? theme.palette.common.black : theme.palette.grey[500]})`,
                    textShadow: theme.palette.type === 'dark' ? `0 0 2px ${theme.palette.common.black}` : null,
                    margin: '0 -25%',
                    zIndex: 2
                },

                '& strong': {
                    fontSize: '1.1em'
                },

                '& .innerGraphic': {
                    position: 'absolute',
                    zIndex: -1,
                }


            },

            '&.bare': {
                marginTop: '2vmax',

                '& .inner': {
                    border: 'none',
                    background: 'none'
                }
            },



            
            [theme.breakpoints.down('sm')]: {

                marginTop: '-6%',

                '& .inner': {
                    height: infoboxWidth_sm,
                    width: infoboxWidth_sm
                },

                    
                '& .MuiDivider-root': {
                    width: `calc(100% - ${infoboxWidth_sm})`,
                },
            }
        },

    })
  });
  


  
    
export const SlideStyleCtx = React.createContext<ReturnType<typeof useStyles>>({} as any);
// ------------------------- interfaces -------------------------


Highcharts.setOptions({
	lang: {
  	thousandsSep: ','
  }
})

// ------------------------- component def -------------------------
export const SlideDeck = () => {

  const classes = useStyles(); 
  const isMobile = UICore.useIsMobile();

  const [slideIdx, setSlideIdx] =  useRecoilState<number>(AppState.atoms.slideIdx);


    const slideMeta = {
        "TheSIS":           Slides.Title,
        "Education Crisis": Slides.EduCrisis,
        "Tech Shortage":    Slides.DevShortage,        
        "Education Gap":    Slides.EduGap1,
        "Experience Gap":   Slides.EduGap2, 
        "Solution" :        Slides.Solution1,
        "Our Story":        Slides.AboutUs,
        "Our Belief" :      Slides.Solution2,
        "Our Program":      Slides.OurProgram,
        "Track Record":     Slides.TrackRecord,
        "Pilot":            Slides.PilotProgram,
        "Our Supporters":   Slides.Supporters,
        "Goal":             Slides.Mission,
        "Donate":           Slides.Donate
    };
    const slides = _(slideMeta).values();

    const setSlideMeta = useSetRecoilState<Record<string, any>>(AppState.atoms.slidesMeta);




    const auxMeta = {
        "Student Dropouts": Aux.StudentDropouts,
    };
    const setAuxContent = useSetRecoilState<Record<string, any>>(AppState.atoms.auxMeta);

// 2021.11.29 ERL - want the deck defined here, but because we're using components, we have to wait for this to be initialized at runtime
  React.useEffect(()=>{
    setSlideMeta(slideMeta);
    setAuxContent(auxMeta);
  },[])


  const renderSlide = (component:any) =>{
      return <Mui.Grid className={classes.slide} >
          <Mui.Box component={component} />
      </Mui.Grid>
  }


    return <SlideStyleCtx.Provider value={classes}>
  
        <Mui.Box className={classes.root} id="deckContainer">
            { isMobile  
                ? _(slides).map(renderSlide)
                : renderSlide(slides[slideIdx]) }
        </Mui.Box>


        <GlobalAuxPopup/>

    </SlideStyleCtx.Provider>;
};




const usePopupStyles = makeStyles((theme: Theme) => ({
    root: {
        maxWidth: '50rem'
    },

}));
const GlobalAuxPopup = () => {
    const classes = usePopupStyles();

    const auxMeta = useRecoilValue<Record<string, any>>(AppState.atoms.auxMeta);
    const [openAux, setOpenAux] = useRecoilState<string>(AppState.atoms.openAux);

    const auxComponent = auxMeta[openAux];

    const popupContent = <Mui.Box component={auxComponent} />;

    return auxComponent 
        ? <SD.Popup content={popupContent} classes={{paper:classes.root}} open={true} onClose={()=>setOpenAux(null)} />
        : null;
}