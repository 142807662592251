import _ from 'underscore';
import $ from 'jquery';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const getSlideImg = async () => {

    // 2021.12.08 ERL - prep things for html2canvas...

    // 2021.12.08 ERL- icons
    var icons = $('svg.svg-inline--fa');
    _(icons).each($icon => {


        $icon.setAttribute("width", ($icon.clientWidth * 2).toString());
        $icon.setAttribute("height", $icon.clientHeight.toString());

        $icon.setAttribute("opacity", Math.max(parseFloat($icon.style.opacity), .5).toString());
        ($icon.style.opacity as any) = null;
    })




    const input = document.getElementById('deckContainer') as any;
    const canvas = await html2canvas(input);
            
    const imgData = canvas.toDataURL("image/jpeg", 0.5) as any;
    return imgData;
}

const slideDelay = (durationMs:number) => {
    return new Promise((res)=>{
        _.delay(()=>res(true), durationMs)
    })
}



const makePdf = async (
        startIdx:number, 
        endIdx:number,

        setLoading:(val:boolean)=>any,
        setSlideIdx:(val:number)=>any,
        setStaticMode:(val:boolean)=>any,    
    ) => {

    setLoading(true);
    setSlideIdx(startIdx);
    setStaticMode(true);
    
    const pdfHeight = 9;
    const pdfWidth = 16;
    const pdf = new jsPDF(
        'l',
        'in',
        [pdfHeight, pdfWidth],
        true); //: [ 595.28,  841.89],


    let currIdx = startIdx; // 2021.11.30 ERL - do NOT use appstate...complicated to track the various initialized func instances
    const addSlides = async () => {         
        await slideDelay(100); // 2021.11.30 ERL - wait for render + animations   
        console.log(`adding page ${currIdx}`)
        const slideImg = await getSlideImg();        
        pdf.addImage(slideImg, "jpeg", 0, 0, pdfWidth, pdfHeight, '', "SLOW");

        if(currIdx < endIdx){
            pdf.addPage([pdfHeight, pdfWidth], 'l');
            setSlideIdx(currIdx + 1); // advance slide
            currIdx++; // own bookkeeping; see above
            await addSlides();   
        }
    }
    
    await addSlides();

    pdf.save(`TheSis - ${dayjs().format('MMM YYYY')}.pdf`);
    setLoading(false);
    setSlideIdx(startIdx);
    setStaticMode(false);
    // pdf.output('dataurlnewwindow');

    // var link = document.getElementById('test') as any;
    // link.setAttribute('download', 'test.png');
    // link.setAttribute('href', imgData.replace("image/png", "image/octet-stream"));
    // link.click();
    
}

const effer = {
    getSlideImg: getSlideImg,
    makePdf: makePdf
}

export default effer;