import PubSub from 'pubsub-js'
import _ from 'underscore';
import dayjs from 'dayjs';
import $ from 'jquery';
import localforage from "localforage";

import config from './config'

import {
    atom,
    selector,
    RecoilState, GetRecoilValue,
    RecoilValueReadOnly,
    useRecoilValue,
    useRecoilState
} from 'recoil';

import atoms from './atoms'



class AppState {

    
    atoms: typeof atoms;


    constructor() {

        this.atoms = atoms
        //  _(atoms).mapObject((atom, name) => {
        //     return {
        //         atom: atom,
        //         useValue: () => useRecoilValue(atom),
        //         useState: () => useRecoilState(atom)
        //     }
        // })
    }
        
    sub(channel:string, cb:any){
        PubSub.subscribe(channel, cb);

        return cb;
    }

    unsub(cb:any){
        return PubSub.unsubscribe(cb);
    }

    pub(channel:string, val:any){
        return PubSub.publish(channel, val);
    }


    
} 

(window as any).AppState = new AppState();
(window as any).app_version = config.currentVersion;

(window as any).$ = $;
(window as any)._ = _;
(window as any).dayjs = dayjs;


export default (window as any).AppState






