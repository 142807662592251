import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';


import {
    Accordion, AccordionActions, AccordionDetails, AccordionSummary,
    Avatar,
    Backdrop,
    Badge,
    Box,
    Button,ButtonGroup,
    Card, CardActions, CardContent,
    Checkbox,
    CircularProgress,
    ClickAwayListener,
    Dialog, DialogContent, DialogActions, 
    Divider,
    Drawer,

    Fab,

    FormControl, 
    Grid,
    Icon,IconButton,

    ImageList, ImageListItem,

    InputLabel,
    LinearProgress, Link,

    List,ListItem,ListItemText,ListItemIcon,ListSubheader,

    Menu, MenuItem,

    Select,
    Table,TableBody,TableHead, TableRow, TableCell,

    Tab, Tabs,

    TextField,

    Toolbar,Theme,
    makeStyles, createStyles,  fade, useTheme, useMediaQuery, Typography, TableFooter, Tooltip, 

} from '@material-ui/core';


import {
    Alert,
    AlertTitle,
} from '@material-ui/lab'

const useStdStyles = makeStyles((theme:Theme) => createStyles({     
    faded: {
        opacity: .33,
    } ,


    bgIcon: {
        opacity: .15,
        position: 'absolute',
        zIndex: -1,
        filter: 'blur(.5px)'
    },

    toolbar: {
        justifyContent: 'space-between'
    },

    toolbarGroup: {
        width: 'auto',
        display: 'flex',
        alignItems: 'center'
    },

    tab: {
        textTransform: 'none',
        
        maxWidth: 'none',
        '& .MuiBadge-root': {
            alignItems: 'center'                
        },

        '& .MuiTab-wrapper':{
            flexDirection: 'row',
        },

        '& .MuiChip-root': {
            height: '1.5em',
            '& .MuiChip-label': {
                padding: theme.spacing(0, 1)
            }
        }
    },

    chip: {
        height: '1.5em',
        '& .MuiChip-label': {
            padding: theme.spacing(0, 1)
        }
    },

    test: {
        background: 'red'
    },


    modalMenu: {

    }
}));

export type StdClasses = ReturnType<typeof useStdStyles>;

export default {
    Icon:  FontAwesomeIcon,

    Spinner: () => {
        const theme = useTheme();

        return  <Backdrop open={true} 
            style={{
                position: 'absolute',
                zIndex: theme.zIndex.speedDial,
            }} >
            <Box style={{position:"relative", display:"inline-flex" }}>
                <CircularProgress 
                    size={40} 
                    color={'primary'}  
                    variant={'indeterminate'}
                    />
            </Box>
        
        </Backdrop>
    },

    TabPanel: (props: { children:any, value:any, viewing:any}) => {
        if(props.value !== props.viewing)
            return null;

        return <>
            {props.children}
        </>
    },

    clsx: clsx,
        
    useStdStyles: useStdStyles,

    cancelBubbling: {
        onClick     : (e:any)=>{e.stopPropagation();},
        onMouseDown : (e:any)=>{e.stopPropagation();},
        onKeyPress  : (e:any)=>{e.stopPropagation();},
        onTouchStart: (e:any)=>{e.stopPropagation();}
    },


    
    StyleContext: React.createContext<{
        stdClasses: StdClasses
    }>({} as any),

    
    useStyleCtxValue: () => {
        const theme = useTheme();        
        return {
            stdClasses: useStdStyles(theme),
        }
    },


    
    useIsMobile: () => {
        const theme = useTheme();

        return useMediaQuery(theme?.breakpoints.down('xs'));
    }

}

export const Mui = {
    Accordion, AccordionActions, AccordionDetails, AccordionSummary,
    Alert, AlertTitle,
    Avatar,
    Badge,
    Box,Button,ButtonGroup,
//    Chip, // 2021.09.30 ERL - errors when using this one vs direct import
    Card,CardActions, CardContent,
    Checkbox,
    CircularProgress,

    ClickAwayListener,
    
    Dialog,DialogContent, DialogActions,
    Divider,
    Drawer,
    Fab,
    FormControl, 
    Grid,
    IconButton,
    ImageList, ImageListItem,
    InputLabel,
    LinearProgress, Link,
    List,ListItem,ListItemText,ListItemIcon,ListSubheader,
    Menu, MenuItem,
    Select,
    Table,TableBody,TableHead, TableRow, TableCell,
    Tab, Tabs, 

    TextField,
    Tooltip,

    Toolbar, Typography

}