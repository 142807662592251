import packageJson from '../../package.json';


const isLocalhost = Boolean(
    window.location.hostname === 'mren-na1-localhost.io' ||
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
  );

const host = "https://" + window.location.hostname

var prodConfig = {
    ENV: "PROD",

    currentVersion: packageJson.version,
    versionCheckUrl: host + '/meta.txt',


    RootUrl: host,

    contactEmail: "codethesis.org@gmail.com",

    APIs: {
        emailjs: {
            UserId: 'user_UluhGMgvzxpKleonT8kmJ',
            ServiceId: 'service_spoyecb',
            TemplateId: 'template_923u9lb'
        }
    }
}

var devConfig = {
    ENV: "LOCAL",
    // versionCheckUrl: host + ':3000/meta.txt',
}



const config = isLocalhost
    ? Object.assign(prodConfig, devConfig)
    : prodConfig;


export default config; 