// ------------------------- lib imports -------------------------
import React, { ReactDOM as ReactDOMtest } from 'react';
import _ from 'underscore';
import $ from 'jquery';
import dayjs from 'dayjs';

import AppState from '../../data/AppState'
import UICore, { Mui } from '../UICore';

// ------------------------- component imports -------------------------
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import * as SD from "./SlideComponents"




// ------------------------- material-ui imports -------------------------
import {
    makeStyles, createStyles, Theme,fade, useTheme, StyleRules, lighten
  } from '@material-ui/core';
 import {
    red
 } from '@material-ui/core/colors';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';




export const StudentDropouts = () => {


    return <>
    
        <Mui.Typography variant="h4">
            Highschool Dropouts
        </Mui.Typography>
        <Mui.Typography variant="body1">
            Our education system is increasingly failing to keep and prepare the next generation.  
        </Mui.Typography>

        <Mui.Typography variant="h5">
            The Numbers
        </Mui.Typography>
        
        <Mui.Typography variant="body1">
            asdfa
            asdfasdf

            
            <Citations sources={["dosomething.org"]}/> 
        </Mui.Typography>

        
        <Mui.Typography variant="h5">
            The Reasons
        </Mui.Typography>
        <Mui.Typography variant="body1">
            
            While many factors outside of school contribute to why students drop out, among the top reasons were 
            &nbsp;<strong className="warning">boredom</strong> and finding curricula 
            &nbsp;<strong className="warning">irrelevant</strong> to their lives.  

        </Mui.Typography>



        <Citations sources={["dosomething.org"]}/> 
        
    </>;
}




const useCitationStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            padding: theme.spacing(1)
        }, 
    })
});
export const Citations = (props:{
    sources:any[]
}) => {
    const classes = useCitationStyles();
    return <Mui.Card elevation={0}>
        <Mui.CardContent>
            <Mui.Typography variant="subtitle1" color="textSecondary">Sources</Mui.Typography>

            <Mui.List>
                {_(props.sources).map((source, idx) => 
                <Mui.ListItem key={idx}>                    
                    {source}
                </Mui.ListItem>)}
            </Mui.List>
        </Mui.CardContent>
    </Mui.Card>
}