// ------------------------- lib imports -------------------------
import React from 'react';
import _ from 'underscore'
import AppState from '../data/AppState';


import {
    useRecoilState,
    useRecoilValue
} from 'recoil'

import dayjs from 'dayjs';
import clsx from 'clsx';


import UICore, { Mui } from './UICore'

// ------------------------- component imports -------------------------


// ------------------------- material-ui imports -------------------------
import {
   Theme,

    makeStyles, createStyles,  fade, useTheme, useMediaQuery,  

} from '@material-ui/core';




// ------------------------- style definitions -------------------------

const useStyles = makeStyles((theme: Theme) => {

    return createStyles({
        root: {
           
        }
    })
});

// ------------------------- interfaces -------------------------



// ------------------------- component def -------------------------

const ThemeToggle = (props:{ 

}) => {
    const classes = useStyles();
   
    const [isDarkMode, setIsDarkMode] = useRecoilState<boolean>(AppState.atoms.darkMode)
    

    return <Mui.IconButton onClick={()=> setIsDarkMode(!isDarkMode)}>        
        <UICore.Icon icon={isDarkMode ? "lightbulb" : "moon"} />
    </Mui.IconButton>
}

export default ThemeToggle;