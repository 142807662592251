// ------------------------- lib imports -------------------------
import React, { ReactDOM as ReactDOMtest } from 'react';
import _ from 'underscore';
import $ from 'jquery';
import dayjs from 'dayjs';

import AppState from '../data/AppState'

import UICore, { Mui } from './UICore';
import config from '../data/config';


import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';

import { send as emailjs_send } from 'emailjs-com'

import validator from 'validator'

// ------------------------- component imports -------------------------



// ------------------------- material-ui imports -------------------------
import {
    makeStyles, createStyles, Theme,fade, useTheme, StyleRules, useMediaQuery
  } from '@material-ui/core';


  

// ------------------------- style definitions -------------------------

const useStyles = makeStyles((theme: Theme) => {


    return createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },

        form: {
            width: '24rem',
            maxWidth: 'calc(100vw - 2rem)'
        }
    })
  });
  


 
// ------------------------- interfaces -------------------------


// ------------------------- component def -------------------------
const ContactForm = () => {

    const classes = useStyles(); 
    const stdClasses = UICore.useStdStyles();

    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);
    const [success, setSuccess] = React.useState<boolean>(false);

    const [toSend, setToSend] = React.useState({
        from_name: '',
        to_name: 'Code TheSIS info',
        message: '',
        reply_to: '',
      });

    const valid = !!toSend.from_name && !!toSend.message && validator.isEmail(toSend.reply_to);

      const onSubmit = (e:any) => {
        e.preventDefault();
        console.log("hiiii");
        console.log(toSend);

        setLoading(true);

        emailjs_send(
                config.APIs.emailjs.ServiceId,
                config.APIs.emailjs.TemplateId,
                toSend,
                config.APIs.emailjs.UserId)
            .then((response) => {
                setSuccess(true);
                setLoading(false);
            })
            .catch((err) => {
                setError(true);
                setLoading(false);
            })

      };
    
      const handleChange = (e:any) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
      };



    return <Mui.Card>

        {isLoading && <UICore.Spinner/>}

        {!success &&
        <form id="contactUs" onSubmit={onSubmit} className={classes.form}>
        
            <Mui.CardContent className={classes.root}>
                <Mui.TextField onChange={handleChange} required fullWidth
                    variant="standard"
                    name="from_name" 
                    value={toSend.from_name} 
                    placeholder="Name" />
                <Mui.TextField onChange={handleChange} required fullWidth
                    variant="standard"
                    name="reply_to" 
                    value={toSend.reply_to} 
                    placeholder="Email Address"
                    error={!!toSend.reply_to && !validator.isEmail(toSend.reply_to)}
                    helperText={!toSend.reply_to || validator.isEmail(toSend.reply_to) ? null : "Please enter a valid email address"}
                    />
                <Mui.TextField onChange={handleChange} required fullWidth
                    variant="standard"
                    minRows={5}
                    maxRows={10}
                    name="message" 
                    value={toSend.message}
                    multiline
                    placeholder="Message" 
                    />

            </Mui.CardContent>

            <Mui.CardActions style={{justifyContent: 'flex-end'}}>
                <Mui.Button disabled={!valid} variant="contained" color="primary" onClick={onSubmit}>Send</Mui.Button>
            </Mui.CardActions>
        </form>}

        {success && <Mui.Alert>
            Message sent.  Thank you for your interest and support!
        </Mui.Alert>}

        {error && <Mui.Alert severity="warning" variant="standard">
            Hm, there was a problem... please email us at <Mui.Link href={`mailto:${config.contactEmail}`}>{config.contactEmail}</Mui.Link>.
        </Mui.Alert>}
    </Mui.Card>
    
    
};

export default ContactForm