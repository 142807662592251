// ------------------------- lib imports -------------------------
import _ from 'underscore'
import $ from 'jquery';
import React from 'react'
import {
    useRecoilState, useSetRecoilState,
} from 'recoil'
import AppState from '../data/AppState'

import UICore, { Mui } from './UICore';

import pdeffer from '../util/pdeffer'


// ------------------------- component imports -------------------------



import ContactButton, { goToContact } from './ContactButton';

// ------------------------- material-ui imports -------------------------
import {
    makeStyles, createStyles, Theme, ImageListItemBar,
  } from '@material-ui/core';

// ------------------------- style definitions -------------------------
const useStyles = makeStyles((theme: Theme) => {


    return createStyles({
        
        menuBtn: {
            
        },

        drawerPaper: {
            width: '15rem'
        },

        
    })
});


// ------------------------- interfaces -------------------------




// ------------------------- component def -------------------------
const MainMenu:React.FC = () => {
    const classes = useStyles();
    const stdClasses = UICore.useStyleCtxValue().stdClasses;
    const isMobile = UICore.useIsMobile();
    
    const [open, setOpen] = React.useState<boolean>(false);

    
    const [slideIdx, setSlideIdx] =  useRecoilState<number>(AppState.atoms.slideIdx);
    const slidesMeta = useRecoilState<Record<string, any>>(AppState.atoms.slidesMeta) || [[]];
    const slideTitles = _(slidesMeta[0]).keys();


    const auxMeta = useRecoilState<Record<string, any>>(AppState.atoms.auxMeta) || [[]];
    const auxTitles = _(auxMeta[0]).keys();

    const setOpenAux = useSetRecoilState(AppState.atoms.openAux);

    return <>
            <Mui.IconButton onClick={(e)=>{ e.stopPropagation(); setOpen(true)}} className={classes.menuBtn}>
                <UICore.Icon icon="bars"/>
            </Mui.IconButton>

            <Mui.Drawer
                variant="temporary"
                anchor={'left'}
                open={open}
                onClose={()=>setOpen(false)}
                classes={{
                    paper: classes.drawerPaper,
                }} >
                
                <Mui.List>            
                    <Mui.ListItem>
                        <Mui.Typography variant="h6" align="center">TheSIS</Mui.Typography>
                    </Mui.ListItem>
                    <Mui.ListItem>
                        <Mui.ButtonGroup>
                            <Mui.Button onClick={()=>{
                                goToContact(setSlideIdx, slidesMeta, isMobile);
                                setOpen(false);
                            }} startIcon={<UICore.Icon className={stdClasses.faded} icon="envelope"/>}>
                                Contact / Donate
                            </Mui.Button>
                        </Mui.ButtonGroup>
                    </Mui.ListItem>
                    <Mui.ListItem>
                        <SaveDeck/>
                        {/* <a id="test" hidden>download</a> */}
                    </Mui.ListItem>
                    
                </Mui.List>

                <Mui.Divider variant="middle"/>

                <Mui.List subheader={<Mui.ListSubheader>Table of Contents</Mui.ListSubheader>}>            
                    {_(slideTitles).map((title:string, idx:number) => 
                    <Mui.ListItem button selected={idx === slideIdx} onClick={()=>setSlideIdx(idx)}>
                        {title}
                    </Mui.ListItem>)}
                </Mui.List>
                
                <Mui.Divider variant="middle"/>

                {/* <Mui.List subheader={<Mui.ListSubheader>More Info</Mui.ListSubheader>}>            
                    {_(auxTitles).map((title:string, idx:number) => 
                    <Mui.ListItem button onClick={()=> {
                            setOpenAux(title);
                            setOpen(false);
                        }}>
                        {title}
                    </Mui.ListItem>)}
                </Mui.List> */}
            
             
            </Mui.Drawer>      
    </>
}
export default MainMenu




const SaveDeck = () => {

    const stdClasses = UICore.useStyleCtxValue().stdClasses;

    const setLoading = useSetRecoilState<boolean>(AppState.atoms.globalLoading);

    const setSlideIdx =  useSetRecoilState<number>(AppState.atoms.slideIdx);
    const slidesMeta = useRecoilState<Record<string, any>>(AppState.atoms.slidesMeta) || [[]];

    const setStaticMode = useSetRecoilState<boolean>(AppState.atoms.staticMode);
    
    // 2021.11.30 ERL - keep out here so we redefine it each time?


    const printDeck = async () => {
        // 2022.01.06 ERL - skip donate slide
        await pdeffer.makePdf(0, _(slidesMeta[0]).keys().length -2, setLoading, setSlideIdx, setStaticMode);        
    }


    return <Mui.ButtonGroup>
        <Mui.Button onClick={printDeck} startIcon={<UICore.Icon className={stdClasses.faded} icon="file-pdf"/>}>
            PDF
        </Mui.Button>
        {/* <Mui.Button onClick={printDeck} startIcon={<UICore.Icon className={stdClasses.faded} icon="print"/>}>
            Print
        </Mui.Button> */}
    </Mui.ButtonGroup>
}


