// ------------------------- lib imports -------------------------
import _ from 'underscore'
import React from 'react'
import {
    useRecoilState, useSetRecoilState,
} from 'recoil'
import AppState from '../data/AppState'
import jsPDF from 'jspdf';

import UICore, { Mui } from './UICore';

import pdeffer from '../util/pdeffer';
// ------------------------- component imports -------------------------


// ------------------------- material-ui imports -------------------------
import {
    makeStyles, createStyles, Theme,
  } from '@material-ui/core';

// ------------------------- style definitions -------------------------
const useStyles = makeStyles((theme: Theme) => {


    return createStyles({
        
        toolbar: {
            flexDirection: "row",
            justifyContent: "center"    
        },

        toolbarItem: {
            margin: theme.spacing(0, 1)
        }
    })
});


// ------------------------- interfaces -------------------------




// ------------------------- component def -------------------------
const GlobalControls:React.FC = () => {
    const classes = useStyles();
    
    const [slideIdx, setSlideIdx] =  useRecoilState<number>(AppState.atoms.slideIdx);
    const slidesMeta = useRecoilState<Record<string, any>>(AppState.atoms.slidesMeta) || [[]];
    const setLoading = useSetRecoilState<boolean>(AppState.atoms.globalLoading);
    const setStaticMode = useSetRecoilState<boolean>(AppState.atoms.staticMode);

    const stepBack = () => {
        slideIdx > 0 && setSlideIdx(slideIdx - 1)
    }
    const stepForward = () => {
        const slideCount =  _(slidesMeta[0]).keys().length;
        (!slideCount // 2021.11.30 ERL - initting issue sigh.
            || slideIdx < slideCount - 1)
        && setSlideIdx(slideIdx + 1)
    };

    const [previewUri, setPreviewUri] = React.useState<string>('');
    const previewPdf = async () => {
        setStaticMode(true);   

        _.delay(async ()=>{
            const imgData = await pdeffer.getSlideImg();
            setPreviewUri(imgData);   
        }, 100)
        
        _.delay(()=>setStaticMode(false), 100);
    }
    
    const pdfSlide = async () => {
        await pdeffer.makePdf(slideIdx, slideIdx, setLoading, setSlideIdx, setStaticMode);        
    }


    const eventHandlers = {
        "keydown": function(this:Window, e:KeyboardEvent) {
            const keyActionMap = {
                "ArrowLeft": stepBack,
                "ArrowRight": stepForward,
                "PageUp": stepBack,
                "PageDown": stepForward,
                "F10": pdfSlide
            } as Record<string, ()=>any>;


            switch(e.key) {
                case "ArrowLeft": 
                case "ArrowUp": 
                case "PageUp": 
                    stepBack();
                    break;

                case "ArrowRight": 
                case "ArrowDown": 
                case "PageDown": 
                    stepForward();
                    break;

                case "F9": 
                    previewPdf();
                    break;

                case "F10": 
                    pdfSlide();
                    break;
            }
        }
    } as any;

    React.useEffect(()=>{
        _(_(eventHandlers).keys()).each((eventName:string) => {
            window.addEventListener(eventName, eventHandlers[eventName] as any);
        })
        
        return () => {
            _(_(eventHandlers).keys()).each((eventName:string) => {
                window.removeEventListener(eventName, eventHandlers[eventName] as any);
            })
        }
    }, [slideIdx]) // 2021.11.30 ERL - make sure to rerun when idx changes so we create new handlers

    return <Mui.ButtonGroup variant="text" size="large" className={classes.toolbarItem}>

        <Mui.Button onClick={stepBack}>
            <UICore.Icon icon="angle-left" />
        </Mui.Button>
        <Mui.Button onClick={stepForward}>
            <UICore.Icon icon="angle-right" />
        </Mui.Button>            


        <Mui.Dialog open={!!previewUri} onClose={()=>setPreviewUri('')} maxWidth="lg">
            <Mui.DialogContent>
                <img src={previewUri} style={{width: '100%', height: '100%'}}/>
            </Mui.DialogContent>
        </Mui.Dialog>
    </Mui.ButtonGroup>;

}
export default GlobalControls

